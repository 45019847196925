import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import { useSearchParams } from "react-router-dom";

import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/system';

import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SpaIcon from '@mui/icons-material/Spa';
import MapIcon from '@mui/icons-material/Map';

import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet-async';
import capitalizeWords from 'utils/capitalizeWords';

import RegionTitleHeader from '../RegionTitleHeader';
import RegionBasicInfoSection from './RegionBasicInfoSection';
import RegionDirectionsSection from './RegionDirectionsSection';
import { RegionServicesSection, RegionLeisureSection } from './RegionAmenitiesSection';
import RegionMapSection from './RegionMapSection';

const SECTION_QUERY_PARAM = 'section';

const REGION_SECTIONS = {
    BASIC_INFO: 'basic-info',
    REGION_DIRECTIONS: 'region-directions',
    REGION_SERVICES: 'region-services',
    REGION_LEISURE: 'region-leisure',
    REGION_MAP: 'region-map',
};

const REGION_SECTION_TABS = [
    { name: REGION_SECTIONS.BASIC_INFO, idx: 0 },
    { name: REGION_SECTIONS.REGION_DIRECTIONS, idx: 1 },
    { name: REGION_SECTIONS.REGION_SERVICES, idx: 2 },
    { name: REGION_SECTIONS.REGION_LEISURE, idx: 3 },
    { name: REGION_SECTIONS.REGION_MAP, idx: 4 },
];

const REGION_SECTIONS_TITLE_KEYS = {
    [REGION_SECTIONS.BASIC_INFO]: 'regionTab.pageTitleBasicInformation',
    [REGION_SECTIONS.REGION_DIRECTIONS]: 'regionTab.pageTitleRegionDirections',
    [REGION_SECTIONS.REGION_SERVICES]: 'regionTab.pageTitleRegionServices',
    [REGION_SECTIONS.REGION_LEISURE]: 'regionTab.pageTitleRegionLeisure',
    [REGION_SECTIONS.REGION_MAP]: 'regionTab.pageTitleRegionMap',
};

const getTabIdx = (sectionParam) => {
    const tab = REGION_SECTION_TABS.filter(({ name }) => name === sectionParam)[0];
    if (tab) {
        return tab.idx;
    }
        
    throw new Error(`Unknown section: ${sectionParam}`);
};

const getSection = (tabIdx) => {
    const tab = REGION_SECTION_TABS.filter(({ idx }) => idx === tabIdx)[0];
    if (tab) {
        return tab.name;
    }
    
    throw new Error(`Unknown tab: ${tabIdx}`);
};

const IconButtonWithText = ({ icon, text, sx, selected, onClick }) => (
    <Button
        variant={ selected ? 'outlined' : 'text'}
        sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        px: 1,
        py: 1,
        minWidth: "100px",
        ...sx,
        }}
        onClick={onClick}
    >
        {icon}
        <Typography variant="body2">{text}</Typography>
    </Button>
);

IconButtonWithText.propTypes = {
    icon: PropTypes.node,
    text: PropTypes.string,
    sx: PropTypes.object,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
};

const RegionSection = ({ region, regionBasics }) => {

    const theme = useTheme();
    const { t } = useTranslation();

    const [tabIdx, setTabIdx] = useState(null);

    const [searchParams, setSearchParams]= useSearchParams();
    const section = searchParams.get(SECTION_QUERY_PARAM) || REGION_SECTIONS.BASIC_INFO;

    useEffect(() => {
        setTabIdx(getTabIdx(section));
    }, [section]);

    const handleChange = (event, newValue) => {
        
        setTabIdx(newValue);
        if (event) event.preventDefault();
        
        const regionSection = getSection(newValue);
        if (regionSection === REGION_SECTIONS.BASIC_INFO) {
            setSearchParams({});
        } else {
            setSearchParams({ [SECTION_QUERY_PARAM]: regionSection });
        }
    };

    return (
        <>
            <Helmet>
                <title>{`Twoja Okolica .Online - ${capitalizeWords(region.name)} - ${t(REGION_SECTIONS_TITLE_KEYS[section])}`}</title>
            </Helmet>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {region && tabIdx !== null &&
                    <>
                        <Box sx={{ p: 2 }}>
                            <RegionTitleHeader regionName={region.name} />
                        </Box>
                        <Box sx={{ display: 'flex', pl: 2, pb: 1, borderBottom: `1px solid ${theme.palette.primary.lighter}`, overflowX: 'auto' }}>
                            <IconButtonWithText
                                icon={<TravelExploreIcon fontSize="medium" />}
                                text={t('regionTab.tabTitleBasicInformation')}
                                sx={{ mr: 1 }}
                                selected={tabIdx === 0}
                                onClick={() => handleChange(null, 0)}
                            />
                            <IconButtonWithText
                                icon={<AssistantDirectionIcon fontSize="medium" />}
                                text={t('regionTab.tabTitleRegionDirections')}
                                sx={{ mr: 1 }}
                                selected={tabIdx === 1}
                                onClick={() => handleChange(null, 1)}
                            />
                            <IconButtonWithText
                                icon={<ShoppingCartIcon fontSize="medium" />}
                                text={t('regionTab.tabTitleRegionServices')}
                                sx={{ mr: 1 }}
                                selected={tabIdx === 2}
                                onClick={() => handleChange(null, 2)}
                            />
                            <IconButtonWithText
                                icon={<SpaIcon fontSize="medium" />}
                                text={t('regionTab.tabTitleRegionLeisure')}
                                sx={{ mr: 1 }}
                                selected={tabIdx === 3}
                                onClick={() => handleChange(null, 3)}
                            />
                            <IconButtonWithText
                                icon={<MapIcon fontSize="medium" />}
                                text={t('regionTab.tabTitleRegionMap')}
                                sx={{ mr: 1 }}
                                selected={tabIdx === 4}
                                onClick={() => handleChange(null, 4)}
                            />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <>
                                { tabIdx === 0 &&
                                    <RegionBasicInfoSection
                                        region={region}                                    
                                        regionBasics={regionBasics}                                    
                                    />
                                }
                            </>
                            <>
                                { tabIdx === 1 &&
                                    <Box key={region.id} sx={{ height: '100%', p: 2 }}>
                                        <RegionDirectionsSection region={region} />
                                    </Box>
                                }
                            </>
                            <>
                                { tabIdx === 2 &&
                                    <Box key={region.id} sx={{ height: '100%', p: 2 }}>
                                        <RegionServicesSection region={region} />
                                    </Box>
                                }
                            </>
                            <>
                                { tabIdx === 3 &&
                                    <Box key={region.id} sx={{ height: '100%', p: 2 }}>
                                        <RegionLeisureSection region={region} />
                                    </Box>
                                }
                            </>
                            <>
                                { tabIdx === 4 &&
                                    <Box key={region.id} sx={{ height: '100%', p: 2 }}>
                                        <RegionMapSection
                                            key={region.id}
                                            region={region}
                                            accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                                        />
                                    </Box>
                                }
                            </>
                        </Box>
                    </>
                }
            </Box>        
        </>

    );
};

RegionSection.propTypes = {
    region: PropTypes.object,
    regionBasics: PropTypes.object,
};

export default RegionSection;