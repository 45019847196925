import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import RegionSharePanel from './RegionSharePanel';

const headerSx = {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: { md: 'center' },
    justifyContent: { xs: 'flex-start', md: 'space-between' },
};

const RegionTitleHeader = ({ regionName }) => (
    <Box sx={headerSx}>
        <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
            {regionName}
        </Typography>
        <RegionSharePanel />
    </Box>
);

RegionTitleHeader.propTypes = {
    regionName: PropTypes.string,
};

export default RegionTitleHeader;
