import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import ShapeMap from 'components/ShapeMap';
import RegionSharePanel from 'pages/RegionPage/RegionSharePanel';

const DEGREES_MARGIN = 0.01; // around one km

const calculateBoundingBox = (coordinates) => (
    {
    minLon: coordinates[0] - DEGREES_MARGIN,
    maxLon: coordinates[0] + DEGREES_MARGIN,
    minLat: coordinates[1] - DEGREES_MARGIN,
    maxLat: coordinates[1] + DEGREES_MARGIN,
});

const getShapes = (amenityCoordinates) => {

    const shapes = [{
        shapeCoordinates: amenityCoordinates,
        featureType: 'Point',
        layerType: 'symbol',
        layerLayout: {'icon-image': 'amenity-marker'},
    }];

    return shapes;
};

const AmenityDetailsMap = ({ amenityCoordinates, accessToken }) => {

    const [amenityBoundingBox, setAmenityBoundingBox] = useState(null);

    useEffect(() => {
        const amenityBoundingBox = calculateBoundingBox(amenityCoordinates);
        setAmenityBoundingBox(amenityBoundingBox);
    }, [amenityCoordinates]);

    return (
        <ShapeMap
            shapes={getShapes(amenityCoordinates)}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            accessToken={accessToken}
            images={[{ marker: 'amenity-marker', url: 'https://img.icons8.com/office/40/marker.png' }]}
            maximizedPanel={<RegionSharePanel iconSize={24} />}
            {...amenityBoundingBox}
        />
    );    
};

AmenityDetailsMap.propTypes = {
    amenityCoordinates: PropTypes.array,
    accessToken: PropTypes.string,
};

export default AmenityDetailsMap;