import PropTypes from 'prop-types';

import { useState, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Breadcrumbs } from '@mui/material';

import { styled } from '@mui/material/styles';

import buildRegionLink from 'routes/buildRegionLink';

import GeoRegion from 'services/GeoRegion';

const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    textTransform: 'capitalize',
    color: theme.palette.grey[600],
    '&:hover': {
        textDecoration: 'underline',
    },
    fontSize: '0.875rem',
}));

const BasicBreadcrumbs = ({ region, regionParents }) => {

    const { voivodeshipRefName, countyRefName, communityRefName, cityRefName } = useParams();
    const [ voivodeship, setVoivodeship ] = useState(null);
    const [ county, setCounty ] = useState(null);
    const [ community, setCommunity ] = useState(null);
    const [ city, setCity ] = useState(null);

    const parentsByRefPath = useMemo(
        () => {
            const parentsByRefPath = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const parent of regionParents) {
                parentsByRefPath[parent.refPath] = parent;
            }
            parentsByRefPath[region.refPath] = region;
            return parentsByRefPath;
        },
        [region, regionParents],
    );

    useEffect(() => {
        const fetchData = async () => {
            if (voivodeshipRefName) {
                const voivodeshipRefPath = GeoRegion.buildRefPath(voivodeshipRefName);
                const voivodeship = parentsByRefPath[voivodeshipRefPath];
                setVoivodeship(voivodeship);
            } else {
                setVoivodeship(null);
            }
            if (countyRefName) {
                const countyRefPath = GeoRegion.buildRefPath(voivodeshipRefName, countyRefName);
                const county = parentsByRefPath[countyRefPath];
                setCounty(county);
            } else {
                setCounty(null);
            }
            if (communityRefName) {
                const communityRefPath = GeoRegion.buildRefPath(voivodeshipRefName, countyRefName, communityRefName);
                const community = parentsByRefPath[communityRefPath];
                setCommunity(community);
            } else {
                setCommunity(null);
            }
            if (cityRefName) {
                const cityRefPath = GeoRegion.buildRefPath(voivodeshipRefName, countyRefName, communityRefName, cityRefName);
                const city = parentsByRefPath[cityRefPath];
                setCity(city);
            } else {
                setCity(null);
            }
        };
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voivodeshipRefName, countyRefName, communityRefName, cityRefName]);

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" separator="›" >
                <StyledLink to="/">
                    Polska
                </StyledLink>
                {voivodeship &&
                    <StyledLink to={buildRegionLink(voivodeshipRefName)}>
                        {voivodeship.name}
                    </StyledLink>
                }
                {county &&
                    <StyledLink to={buildRegionLink(voivodeshipRefName, countyRefName)}>
                        {county.name}
                    </StyledLink>
                }
                {community &&
                    <StyledLink to={buildRegionLink(voivodeshipRefName, countyRefName, communityRefName)}>
                        {community.name}
                    </StyledLink>
                }
                {city &&
                    <StyledLink to={buildRegionLink(voivodeshipRefName, countyRefName, communityRefName, cityRefName)}>
                        {city.name}
                    </StyledLink>
                }
            </Breadcrumbs>
        </>
    );
};

BasicBreadcrumbs.propTypes = {
    region: PropTypes.object.isRequired,
    regionParents: PropTypes.array.isRequired,
};

export default BasicBreadcrumbs;