import PropTypes from 'prop-types';

import { useMemo, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import MessagePanel from 'components/MessagePanel';

import GeoRegion from 'services/GeoRegion';

import { getValuesForRegionTeaser } from './textValues';

const RegionTeaser = ({region, parent, popTrend, ranks, stats, childrenCounts, insights, neighbours, regionCities, nearbyCities}) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const valuesForRegionTeaser = useMemo(
        () => getValuesForRegionTeaser(
            region, parent, popTrend, ranks, stats, childrenCounts, insights, neighbours, regionCities, nearbyCities
        ),
        [region, parent, popTrend, ranks, stats, childrenCounts, insights, neighbours, regionCities, nearbyCities],
    );

    useEffect(() => {
        const handleClick = (event) => {
            const link = event.target.closest('a');
    
            if (link && link.getAttribute('data-teaser-link')) {
                event.preventDefault();
                const path = link.getAttribute('href');
                navigate(path);
            }
        };
    
        // Add click event listener to the document
        document.addEventListener('click', handleClick);
    
        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
      }, [navigate]);
    

    return (
        <Box sx={{ p: 2 }}>
            { region.level === GeoRegion.LEVEL.VOIVODESHIP &&
                <MessagePanel message={t('regionTeaser.voivodeship', valuesForRegionTeaser)} dangerous />
            }
            { region.level === GeoRegion.LEVEL.COUNTY &&
                <MessagePanel message={t('regionTeaser.county', valuesForRegionTeaser)} dangerous />
            }
            { region.level === GeoRegion.LEVEL.COMMUNITY &&
                <MessagePanel message={t('regionTeaser.community', valuesForRegionTeaser)} dangerous />
            }
            { region.level === GeoRegion.LEVEL.CITY &&
                <MessagePanel message={t('regionTeaser.city', valuesForRegionTeaser)} dangerous />
            }
        </Box>
    );
};

RegionTeaser.propTypes = {
    region: PropTypes.object,
    parent: PropTypes.object,
    popTrend: PropTypes.array,
    ranks: PropTypes.object,
    stats: PropTypes.object,
    childrenCounts: PropTypes.array,
    insights: PropTypes.object,
    neighbours: PropTypes.array,
    regionCities: PropTypes.array,
    nearbyCities: PropTypes.array,
};

export default RegionTeaser;