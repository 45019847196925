import PropTypes from 'prop-types';

import { useState, useEffect, useRef } from 'react';

import { Box, Typography } from '@mui/material';
import { OpenInFull, CloseFullscreen } from '@mui/icons-material';

import Map, { GeolocateControl, NavigationControl, Source, Layer, useMap } from 'react-map-gl';
import WebMercatorViewport from 'viewport-mercator-project';

import { useTranslation } from 'react-i18next';

import EVENTS from 'analytics/events';
import sendAnalyticsEvent from 'analytics/sendEvent';

const MapImage = ({ images }) => {

    const { current: map } = useMap();
  
    useEffect(() => {

        // eslint-disable-next-line no-restricted-syntax
        for (const { marker, url } of images) {
            if (!map.hasImage(marker)) {
                map.loadImage(url, (error, image) => {
                    if (error) throw error;
                    if (!map.hasImage(marker)) map.addImage(marker, image, { sdf: false });
                });
            }
        }
    }, [map, images]);

    return null;
};

MapImage.propTypes = {
    images: PropTypes.array,
};

const BASIC_MARGIN_PX = 8;
const MIN_MAP_HEIGHT = 80;

const CUSTOM_PANEL_STYLE = {
    position: 'absolute',
    top: 10,
    right: 88,
    zIndex: 1,
    boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
    background: '#fff',
    borderRadius: '4px',
    p: 0.4,
    height: '29px'
};

const MAP_IMAGE_STYLE = {
    position: 'relative',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transform: 'translate(-50%, -50%)',
};

const ShapeMap = ({
    dimensions,
    customPanel,
    containerMaximized,
    minLon, maxLon, minLat, maxLat,
    shapes,
    mapStyle,
    accessToken, 
    images = [],
    defaultZoom, defaultLon, defaultLat,
    onMaximize, onMove, onMinimize,
}) => {

    const { t } = useTranslation();

    const [viewport, setViewport] = useState(null);
    const [mapImage, setMapImage] = useState(null);
    const [mapImageTimeoutId, setMapImageTimeoutId] = useState(null);

    const mapRef = useRef(null);

    useEffect(() => {
        
        if (dimensions.width && dimensions.height && dimensions.height > MIN_MAP_HEIGHT) {

            if (mapRef.current) {
                mapRef.current.resize();
            }

            const vp = new WebMercatorViewport(dimensions);

            if (defaultZoom && defaultLon && defaultLat) {
                setViewport({
                    zoom: defaultZoom,
                    longitude: defaultLon,
                    latitude: defaultLat,
                });
            } else {
                const {longitude, latitude, zoom} = vp.fitBounds(
                    [
                        [minLon, minLat],
                        [maxLon, maxLat]
                    ],
                    {
                        padding: 40
                    }
                );
                setViewport({ longitude, latitude, zoom });
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dimensions.width, dimensions.height, minLon, minLat, maxLon, maxLat]);

    const createSnapshot = () => {
        const mapImage = mapRef.current.getCanvas().toDataURL('image/png');
        setMapImage(mapImage);
    };

    const handleMove = (zoom, longitude, latitude) => {

        onMove(zoom, longitude, latitude);

        clearTimeout(mapImageTimeoutId);
        const timeoutId = setTimeout(createSnapshot, 300);
        setMapImageTimeoutId(timeoutId);
    };

    const maximizeMap = () => {
        sendAnalyticsEvent(EVENTS.REGION_MAP_MAXIMIZED)
        const { zoom, longitude, latitude } = viewport; 
        onMaximize(zoom, longitude, latitude);
    };

    const minimizeMap = () => {
        onMinimize();
    };

    return (
        <>
            {accessToken && mapStyle ? 
                <>
                    {mapImage &&
                        <img
                            src={mapImage}
                            alt=''
                            className="print-only"
                            style={MAP_IMAGE_STYLE}
                        />
                    }
                    {viewport &&
                        <span className="noprint">
                            <Map
                                {...viewport}
                                ref={mapRef}
                                onMove={evt => {
                                    const { zoom, longitude, latitude } = viewport; 
                                    handleMove(zoom, longitude, latitude); 
                                    setViewport(evt.viewState)
                                }}
                                onLoad={createSnapshot}
                                mapStyle={mapStyle}
                                mapboxAccessToken={accessToken}
                                preserveDrawingBuffer
                            >
                                {customPanel && 
                                    <Box sx={CUSTOM_PANEL_STYLE}>
                                        {customPanel}
                                    </Box>
                                }
                                <Box
                                    className="mapboxgl-ctrl mapboxgl-ctrl-group"
                                    sx={{
                                        position: 'absolute',
                                        top: 10,
                                        right: 49,
                                        zIndex: 1,
                                    }}
                                >
                                    { containerMaximized ?
                                        <button
                                            title={t('map.labelMinimize')}
                                            aria-label={t('map.labelMinimize')}
                                            type="button"
                                            onClick={minimizeMap}
                                        >
                                            <CloseFullscreen fontSize="small" sx={{ mt: 0.4, ml: 0.2 }} />
                                        </button>
                                        :
                                        <button
                                            title={t('map.labelMaximize')}
                                            aria-label={t('map.labelMaximize')}
                                            type="button"
                                            onClick={maximizeMap}
                                        >
                                            <OpenInFull fontSize="small" sx={{ mt: 0.4, ml: 0.2 }} />
                                        </button>
                                    }
                                </Box>              
                                <MapImage images={images} />
                                <GeolocateControl
                                    style={{ right: BASIC_MARGIN_PX, top: BASIC_MARGIN_PX * 9 }}
                                    positionOptions={{enableHighAccuracy: true}}
                                    trackUserLocation
                                />
                                <NavigationControl
                                    style={{ right: BASIC_MARGIN_PX, top: BASIC_MARGIN_PX }}
                                    showCompass={false}
                                />
                                {shapes.map(({ featureType, shapeCoordinates, layerType, layerPaint, layerLayout }, idx) =>
                                    <Source
                                        key={idx}
                                        id={`shape-source-${idx}`}
                                        type="geojson"
                                        data={{ type: 'Feature', geometry: { type: featureType, coordinates: shapeCoordinates } }}
                                    >
                                        <Layer
                                            id={`shape-source-layer-${idx}`}
                                            type={layerType || 'line'}
                                            paint={layerPaint || {}}
                                            layout={layerLayout || {}}
                                        />
                                    </Source>
                                )}
                            </Map>
                        </span>
                    }
                </>
                :
                <Typography
                    color="error"
                    display="block"
                    variant="caption"
                >
                    Error occured when displaying the map
                </Typography>
            }
        </>
    );
};

ShapeMap.propTypes = {
    dimensions: PropTypes.object,
    customPanel: PropTypes.node,
    containerMaximized: PropTypes.bool,

    onMaximize: PropTypes.func,
    onMove: PropTypes.func,
    onMinimize: PropTypes.func,

    minLon: PropTypes.number,
    maxLon: PropTypes.number,
    minLat: PropTypes.number,
    maxLat: PropTypes.number,
    shapes: PropTypes.arrayOf(PropTypes.shape({
        shapeCoordinates: PropTypes.array,
        featureType: PropTypes.string,
        layerType: PropTypes.string,    
        layerPaint: PropTypes.object,    
        layerLayout: PropTypes.object,    
    })),
    accessToken: PropTypes.string,
    mapStyle: PropTypes.string,
    images: PropTypes.array,
    
    defaultZoom: PropTypes.number,
    defaultLon: PropTypes.number,
    defaultLat: PropTypes.number,
};

export default ShapeMap;