import PropTypes from 'prop-types';

import { useState, useEffect } from 'react';

import { useSearchParams } from "react-router-dom";

import { Box, Card, CardContent, Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';

import MessagePanel from 'components/MessagePanel';

import AmenitiesTextFilter from './AmenitiesTextFilter';
import AmenitiesCategoryFilter from './AmenitiesCategoryFilter';
import AmenityRow from './AmenityRow';
import AmenitiesPagination from './AmenitiesPagination';
import AmenitiesCounts from './AmenitiesCounts';
import AmenityDetails from './AmenityDetails';

const AMENITY_ID_QUERY_PARAM = 'amenity-id';

const mapCategoriesByKeys = (categories) => {
    
    const categoriesByCategoryKey = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const category of categories) {
        categoriesByCategoryKey[category.key] = category;
    }

    return categoriesByCategoryKey;
};

const RegionAmenities = ({
    region, amenities, pagesCount, categoryCounts, amenitiesTotal, currentPage, categoryNames,
    categoryGroups, categories, selectedCategories, onSearchTextChange,
}) => {
    
    const { t } = useTranslation();
    const [searchParams, setSearchParams]= useSearchParams();

    const [selectedAmenity, setSelectedAmenity] = useState(null);
    const [categoriesByCategoryKey, setCategoriesByCategoryKey] = useState(null);

    const amenityId = searchParams.get(AMENITY_ID_QUERY_PARAM);

    useEffect(() => {
        if (amenityId) {
            const amenitiesById = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const amenity of amenities) {
                amenitiesById[amenity.id] = amenity;
            }
            const selectedAmenity = amenitiesById[amenityId];
            if (selectedAmenity) {
                setSelectedAmenity(selectedAmenity);
            }
        }
    }, [amenities, amenityId]);

    useEffect(() => {
        const categoriesByCategoryKey = mapCategoriesByKeys(categories);
        setCategoriesByCategoryKey(categoriesByCategoryKey);
    }, [categories]);

    return (
        <>
            {selectedAmenity && categoriesByCategoryKey &&
                <AmenityDetails
                    amenity={selectedAmenity}
                    categoryNames={categoryNames}
                    onAmenityDetailsClosed={
                        () => {
                            setSearchParams((params) => {
                                params.delete(AMENITY_ID_QUERY_PARAM);
                                return params;
                            });
                            setSelectedAmenity(null);
                        }
                    }
                />
            }
            {!selectedAmenity && categoriesByCategoryKey &&
            <>
                <Card sx={{ flexGrow: 1, m: 1, mb: 3  }}>
                    <CardContent>
                        <AmenitiesTextFilter
                            onSearchTextChange={onSearchTextChange}
                        />
                        <AmenitiesCategoryFilter
                            categoryGroups={categoryGroups}
                            categories={categories}
                            selectedCategories={selectedCategories}
                        />
                    </CardContent>
                </Card>
                <Card sx={{ flexGrow: 1, m: 1, mb: 3  }}>
                    <CardContent>
                        { amenities.length ?
                            <>
                                <Box sx={{ mb: 2 }}>
                                    {amenities.map((amenity) =>
                                        <AmenityRow
                                            key={amenity.amenityId}
                                            region={region}
                                            amenity={amenity}
                                            categoryNames={categoryNames}
                                            categoriesByCategoryKey={categoriesByCategoryKey}
                                            onAmenitySelected={
                                                (amenity) => {
                                                    setSearchParams((params) => {
                                                        params.set(AMENITY_ID_QUERY_PARAM, amenity.id)
                                                        return params;
                                                    });
                                                    setSelectedAmenity(amenity);
                                                }
                                            }
                                        />
                                    )}
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <Stack spacing={2}>
                                        <AmenitiesPagination
                                            pagesCount={pagesCount}
                                            currentPage={currentPage}
                                        />
                                    </Stack>
                                </Box>
                                <AmenitiesCounts
                                    region={region}
                                    categoryGroups={categoryGroups}
                                    categoriesByCategoryKey={categoriesByCategoryKey}
                                    categoryCounts={categoryCounts}
                                    amenitiesTotal={amenitiesTotal}
                                />
                            </>
                            :
                            <>
                                <MessagePanel>
                                    {t('regionAmenities.labelNoAmenities')}
                                </MessagePanel>
                            </>        
                        }
                    </CardContent>
                </Card>        
            </>
            }
        </>
    );
};

RegionAmenities.propTypes = {
    region: PropTypes.object,
    amenities: PropTypes.array,
    pagesCount: PropTypes.number,
    categoryCounts: PropTypes.array,
    amenitiesTotal: PropTypes.number,
    currentPage: PropTypes.number,
    categoryNames: PropTypes.object,
    categoryGroups: PropTypes.array,
    categories: PropTypes.array,
    selectedCategories: PropTypes.array,
    onSearchTextChange: PropTypes.func,
};

export default RegionAmenities;
