import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import servicesContext from 'servicesContext';

import MessagePanel from 'components/MessagePanel';

import RegionDirections from './RegionDirections';

const { regionDirectionsService } = servicesContext;

const RegionDirectionsSection = ({ region }) => {

    const [routes, setRoutes] = useState(null);

    useEffect(() => {

        const fetch = async () => {
            const routes = await regionDirectionsService.getRegionRoutes(region.id);
            setRoutes(routes);
        }

        fetch();

    }, [region.id]);

    const { t } = useTranslation();

    return (
        <>
            {region.regionCenters.length > 0 && routes &&
                <RegionDirections
                    region={region}
                    routes={routes}
                />
            }
            {region.regionCenters.length === 0 &&
                <MessagePanel>{t('regionDirections.labelNoDirections')}</MessagePanel>
            }
        </>
    );
};

RegionDirectionsSection.propTypes = {
    region: PropTypes.object,
};

export default RegionDirectionsSection;
