import PropTypes from 'prop-types';

import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { Box, IconButton, Tooltip } from '@mui/material';
import { ContentCopy, PictureAsPdf } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

import copy from 'copy-to-clipboard'; 

import EVENTS from 'analytics/events';
import sendAnalyticsEvent from 'analytics/sendEvent';

import {
    WhatsappShareButton,
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    EmailShareButton,
    WhatsappIcon,
    TwitterIcon,
    FacebookIcon,
    LinkedinIcon,
    EmailIcon,
  } from "react-share";

const ButtonBox = ({
    hint, hideHintAfterClick = true, children, analyticsEvent, pl = 0.1, pr = 0.1,
}) => {

    const [tooltipVisible, setTooltipVisible] = useState(false);

    return (
        <Tooltip
            title={hint}
            open={tooltipVisible}
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            arrow
        >
            <Box
                sx={{
                    pl, pr,
                    '& button': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
    
                }}
                onClick={() => {
                    sendAnalyticsEvent(analyticsEvent);
                    if (hideHintAfterClick) {
                        setTooltipVisible(false);
                    }
                }}
            >
                {children}
            </Box>
        </Tooltip>
    ); 
};
    
    

ButtonBox.propTypes = {
    hint: PropTypes.string,
    hideHintAfterClick: PropTypes.bool,
    children: PropTypes.node,
    analyticsEvent: PropTypes.string,
    pl: PropTypes.number,
    pr: PropTypes.number,
};

const RoundButton = ({ onClick, children, iconSize }) => {

    const theme = useTheme();

    return (
        <IconButton
            sx={{
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                },
                color: 'white',
                width: iconSize,
                height: iconSize,
            }}
            onClick={onClick}
        >
            {children}
        </IconButton>
    );
};
    
RoundButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    iconSize: PropTypes.number,
};
  
const RegionSharePanel = ({ iconSize = 32 }) => {

    const { t } = useTranslation();
    const theme = useTheme();

    const location = useLocation();
    const { protocol } = window.location;
    const { host } = window.location;
    const currentUrl = `${protocol}//${host}${location.pathname}${location.search}${location.hash}`;

    const [pageTitle, setPageTitle] = useState(document.title);
    
    const copyTooltipReady = t('regionHeader.tooltipCopyLink');
    const copyTooltipDone = t('regionHeader.tooltipCopyLinkDone');
    const [copyTooltip, setCopyTooltip] = useState(copyTooltipReady);

    const setTooltipDone = () => {
        setCopyTooltip(copyTooltipDone);
        setTimeout(() => setCopyTooltip(copyTooltipReady), 2000)
    };

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    setPageTitle(document.title);
                }
            });
        });
    
        const titleElement = document.querySelector('title');
        if (titleElement) {
            observer.observe(titleElement, { childList: true });
        }
    
        return () => {
            observer.disconnect();
        };
    }, []);
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }} className="noprint">
            <ButtonBox
                hint={t('regionHeader.tooltipShareOnWhatsapp')}
                analyticsEvent={EVENTS.REGION_WHATS_APP_CLICKED}
            >
                <WhatsappShareButton url={currentUrl} title={pageTitle}>
                    <WhatsappIcon size={iconSize} round />
                </WhatsappShareButton>
            </ButtonBox>
            <ButtonBox
                hint={t('regionHeader.tooltipShareOnTwitter')}
                analyticsEvent={EVENTS.REGION_TWITTER_CLICKED}
            >
                <TwitterShareButton url={currentUrl} title={pageTitle}>
                    <TwitterIcon size={iconSize} round />
                </TwitterShareButton>
            </ButtonBox>
            <ButtonBox
                hint={t('regionHeader.tooltipShareOnFacebook')}
                analyticsEvent={EVENTS.REGION_FACEBOOK_CLICKED}
            >
                <FacebookShareButton url={currentUrl}>
                    <FacebookIcon size={iconSize} round />
                </FacebookShareButton>
            </ButtonBox>
            <ButtonBox
                hint={t('regionHeader.tooltipShareOnLinkedIn')}
                pr={2}
                analyticsEvent={EVENTS.REGION_LINKEDIN_CLICKED}
            >
                <LinkedinShareButton url={currentUrl} title={pageTitle} summary={pageTitle}>
                    <LinkedinIcon size={iconSize} round />
                </LinkedinShareButton>
            </ButtonBox>
            <ButtonBox
                hint={t('regionHeader.tooltipShareOnEmail')}
                analyticsEvent={EVENTS.REGION_EMAIL_CLICKED}
            >
                <EmailShareButton url={currentUrl} subject={pageTitle}>
                    <EmailIcon size={iconSize} round bgStyle={{ fill: theme.palette.primary.main }} />
                </EmailShareButton>
            </ButtonBox>
            <ButtonBox
                hint={copyTooltip}
                analyticsEvent={EVENTS.REGION_COPY_URL_CLICKED}
                hideHintAfterClick={false}
            >
                <RoundButton onClick={() => { copy(currentUrl); setTooltipDone(); }} iconSize={iconSize}>
                    <ContentCopy sx={{ fontSize: iconSize * 0.5 }} />
                </RoundButton>
            </ButtonBox>
            <ButtonBox
                hint={t('regionHeader.tooltipPrintPage')}
                analyticsEvent={EVENTS.REGION_PRINT_CLICKED}
            >
                <RoundButton onClick={() => { setTimeout(() => window.print(), 0) }} iconSize={iconSize}>
                    <PictureAsPdf sx={{ fontSize: iconSize * 0.5 }} />
                </RoundButton>
            </ButtonBox>
        </Box>
    );
};

RegionSharePanel.propTypes = {
    iconSize: PropTypes.number,
};

export default RegionSharePanel;
