const MAP_MAXIMIZED_QUERY_PARAM = 'map-maximized';
const MAP_ZOOM_QUERY_PARAM = 'map-zoom';
const MAP_LON_QUERY_PARAM = 'map-lon';
const MAP_LAT_QUERY_PARAM = 'map-lat';

const getMapParamsFromURL = (searchParams) => {

    const mapMaximized = searchParams.get(MAP_MAXIMIZED_QUERY_PARAM);
    const defaultZoom = searchParams.get(MAP_ZOOM_QUERY_PARAM);
    const defaultLon = searchParams.get(MAP_LON_QUERY_PARAM);
    const defaultLat = searchParams.get(MAP_LAT_QUERY_PARAM);

    return { mapMaximized, defaultZoom, defaultLon, defaultLat };
};

const updateMapParamsInURL = (mapMaximized, zoom, lon, lat, setSearchParams) => {

    setSearchParams((searchParams) => {

        searchParams.set(MAP_MAXIMIZED_QUERY_PARAM, mapMaximized);
        searchParams.set(MAP_ZOOM_QUERY_PARAM, zoom);
        searchParams.set(MAP_LON_QUERY_PARAM, lon);
        searchParams.set(MAP_LAT_QUERY_PARAM, lat);

        return searchParams;
    });
};

const resetMapParamsInURL = (setSearchParams) => {

    setSearchParams((searchParams) => {
        
        searchParams.delete(MAP_MAXIMIZED_QUERY_PARAM);
        searchParams.delete(MAP_ZOOM_QUERY_PARAM);
        searchParams.delete(MAP_LON_QUERY_PARAM);
        searchParams.delete(MAP_LAT_QUERY_PARAM);

        return searchParams;
    });    
};

export { getMapParamsFromURL, updateMapParamsInURL, resetMapParamsInURL };
