import PropTypes from 'prop-types';

import { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import ShapeMap from './ShapeMap';
import { getMapParamsFromURL, updateMapParamsInURL, resetMapParamsInURL } from './mapParams';

const CONTAINER_STYLE_DEFAULT = {
    height: '100%',
    overflow: 'hidden',
};

const CONTAINER_STYLE_MAXIMIZED = {
    backgroundColor: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9999,
    overflow: 'hidden',
};

const ShapeMapContainer = ({
    maximizedPanel,
    minLon, maxLon, minLat, maxLat,
    shapes,
    mapStyle = 'mapbox://styles/mapbox/streets-v11',
    accessToken, 
    images = [],
}) => {

    const [searchParams, setSearchParams]= useSearchParams();
    const { mapMaximized, defaultZoom, defaultLon, defaultLat } = getMapParamsFromURL(searchParams);

    const [containerMaximized, setContainerMaximized] = useState(mapMaximized);
    
    const mapContainerRef = useRef(null);
    const [dimensions, setDimensions] = useState({
        width: null, height: null,
    });

    useEffect(() => {
        if (mapMaximized) {
            document.body.style.overflowY = 'hidden';
        }
    }, [mapMaximized]);

    useEffect(() => {

        const observer = new ResizeObserver(entries => {
            const entry = entries[0];
            const { width, height } = entry.contentRect;
            setDimensions({ width, height });
        });
        observer.observe(mapContainerRef.current);
    
        const { width, height } = mapContainerRef.current.getBoundingClientRect();
        setDimensions({ width, height });

        return () => observer.disconnect();
    }, []);

    const handleMaximize = (zoom, lon, lat) => {
        document.body.style.overflowY = 'hidden';
        setContainerMaximized(true);
        updateMapParamsInURL(true, zoom, lon, lat, setSearchParams);
    };

    const handleMove = (zoom, lon, lat) => {
        if (containerMaximized) {
            updateMapParamsInURL(true, zoom, lon, lat, setSearchParams);
        }
    };

    const handleMinimize = () => {
        document.body.style.overflowY = 'auto';
        setContainerMaximized(false);
        resetMapParamsInURL(setSearchParams)
    };
    
    return (
        <div
            ref={mapContainerRef}
            style={containerMaximized ? CONTAINER_STYLE_MAXIMIZED : CONTAINER_STYLE_DEFAULT}
            className='shape-map'
        >
            <ShapeMap
                dimensions={dimensions}
                customPanel={containerMaximized ? maximizedPanel : null}
                containerMaximized={containerMaximized}

                onMaximize={handleMaximize}
                onMove={handleMove}
                onMinimize={handleMinimize}

                minLon={minLon}
                maxLon={maxLon}
                minLat={minLat}
                maxLat={maxLat}
                shapes={shapes}
                mapStyle={mapStyle}
                accessToken={accessToken}
                images={images}
                
                defaultZoom={defaultZoom}
                defaultLon={defaultLon}
                defaultLat={defaultLat}
            />
        </div>
    );
};

ShapeMapContainer.propTypes = {
    maximizedPanel: PropTypes.node,
    minLon: PropTypes.number,
    maxLon: PropTypes.number,
    minLat: PropTypes.number,
    maxLat: PropTypes.number,
    shapes: PropTypes.arrayOf(PropTypes.shape({
        shapeCoordinates: PropTypes.array,
        featureType: PropTypes.string,
        layerType: PropTypes.string,    
        layerPaint: PropTypes.object,    
        layerLayout: PropTypes.object,    
    })),
    accessToken: PropTypes.string,
    mapStyle: PropTypes.string,
    images: PropTypes.array,
};

export default ShapeMapContainer;