import { Helmet } from 'react-helmet-async';


// @mui
import {
    Card,
    Container,
} from '@mui/material';

export default function InfoPrivacyPolicy() {

    return (
        <>
            <Helmet>
                <title>Twoja Okolica .Online | Polityka Prywatności</title>
            </Helmet>

            <Container>

                <Card sx={{ margin: 2, padding: 2 }}>
                    <h3>Polityka Prywatności Twoja Okolica .Online</h3>                    
                        <p>
                        Data wejścia w życie: 2024-10-24
                        </p>
                        <p>
                        Na stronie Twoja Okolica .Online (<a href='https://twojaokolica.online' target='_blank' rel='noreferrer'>https://twojaokolica.online</a>) prywatność użytkowników ma dla nas ogromne znaczenie. Niniejsza Polityka Prywatności wyjaśnia, w jaki sposób zbieramy, wykorzystujemy i chronimy Twoje dane podczas odwiedzania naszej strony internetowej. Korzystając z naszej witryny, akceptujesz zasady opisane w tej polityce.
                        </p>
                        <p>
                        <h4>1. Zbieranie danych</h4>
                        Twoja Okolica .Online nie zbiera bezpośrednio danych osobowych użytkowników. Jednakże, na naszej stronie używamy usług stron trzecich, takich jak Google Analytics 4 (GA4) oraz Google AdSense, które mogą zbierać dane w ramach swoich usług.
                        </p>
                        <p>
                        <b>Pliki cookie</b>
                        </p>
                        <p>
                        Nasza strona używa plików cookie, aby poprawić komfort użytkowania i wyświetlać odpowiednie reklamy. Korzystając z naszej witryny, zgadzasz się na używanie plików cookie. Masz jednak możliwość zarządzania ustawieniami dotyczącymi plików cookie:
                        </p>
                        <p>
                        Odrzucenie plików cookie: Możesz zrezygnować z plików cookie, dostosowując ustawienia przeglądarki lub za pomocą baneru zgody na pliki cookie dostępnego na naszej stronie.
                        </p>
                        <p>
                        Dostosowanie zgody: Możesz także dostosować, które pliki cookie chcesz zaakceptować, korzystając z baneru zgody.
                        Aby uzyskać więcej informacji na temat tego, w jaki sposób Google używa plików cookie i danych, zapoznaj się ze stroną <a href='https://policies.google.com/technologies/cookies?hl=pl' target='_blank' rel='noreferrer'>Prywatność i Warunki Google</a>.
                        </p>
                        <p>
                        <h4>2. Google Analytics 4 (GA4)</h4>
                        Korzystamy z Google Analytics 4 (GA4) do śledzenia ruchu na stronie internetowej oraz analizowania, jak użytkownicy korzystają z naszej witryny. GA4 może zbierać informacje takie jak adres IP, typ przeglądarki, typ urządzenia i zachowania użytkowników na stronie. Dane te są anonimizowane i nie mamy dostępu do danych umożliwiających identyfikację osoby.
                        </p>
                        <p>
                        Aby dowiedzieć się więcej o tym, jak Google zbiera i przetwarza dane, zapoznaj się z <a href='https://support.google.com/analytics/answer/6004245' target='_blank' rel='noreferrer'>Polityką Prywatności i Bezpieczeństwa Danych Google</a>.
                        </p>
                        <p>
                        <h4>3. Google AdSense</h4>
                        Twoja Okolica .Online korzysta z Google AdSense do wyświetlania spersonalizowanych reklam użytkownikom. Google AdSense używa plików cookie, aby wyświetlać reklamy na podstawie wcześniejszych wizyt na naszej stronie oraz innych stronach internetowych. Reklamy mogą być dostosowywane na podstawie zainteresowań, lokalizacji oraz zachowań przeglądania.
                        </p>
                        <p>
                        Możesz zarządzać swoimi preferencjami dotyczącymi reklam lub zrezygnować z personalizowanych reklam, odwiedzając <a href='https://myadcenter.google.com/home?sasb=true&ref=ad-settings&hl=pl' target='_blank' rel='noreferrer'>Ustawienia Reklam Google</a>.
                        </p>
                        <p>
                        <h4>4. Zarządzanie danymi przez strony trzecie</h4>
                        Wszelkie prośby o usunięcie lub modyfikację danych powinny być kierowane bezpośrednio do Google Analytics 4 lub Google AdSense, ponieważ nie przechowujemy ani nie zarządzamy takimi danymi bezpośrednio. Możesz skontaktować się z tymi usługami za pośrednictwem następujących zasobów:
                        </p>
                        <p>
                        Google Analytics 4: <a href='https://support.google.com/analytics/?hl=pl' target='_blank' rel='noreferrer'>Centrum pomocy Google dotyczące prywatności</a>
                        <br/>
                        Google AdSense: <a href='https://support.google.com/adsense/?hl=pl' target='_blank' rel='noreferrer'>Centrum pomocy Google AdSense</a>
                        <h4>5. Twoja zgoda</h4>
                        Korzystając z naszej witryny, wyrażasz zgodę na naszą Politykę Prywatności i akceptujesz jej warunki.
                        </p>
                        <p>
                        <h4>6. Zmiany w Polityce Prywatności</h4>
                        Od czasu do czasu możemy aktualizować niniejszą Politykę Prywatności, aby odzwierciedlała zmiany w naszych praktykach lub usługach. Zaktualizowana polityka zostanie opublikowana na tej stronie z nową "Datą wejścia w życie".
                        </p>
                        <p>
                        <h4>7. Kontakt</h4>
                        Jeśli masz jakiekolwiek pytania lub wątpliwości dotyczące naszych praktyk prywatności, skontaktuj się z nami:
                        <br/>
                        Email: kontakt@twojaokolica.online
                        <br/>
                        Strona internetowa: <a href='https://twojaokolica.online' target='_blank' rel='noreferrer'>https://twojaokolica.online</a>
                        </p>
               </Card>
            </Container>
        </>
    );
}