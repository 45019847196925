import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import PreviousRegion from './PreviousRegion';
import Breadcrumbs from './Breadcrumbs';
import SelectSubRegion from './SelectSubRegion';

const currentRegionBarSx = {
    alignItems: { md: 'center' },
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: { xs: 'flex-start', md: 'space-between' },
    p: 2,
};

const Header = ({ region, regionParents, regionChildren }) => (
    <>
        <PreviousRegion />
        <Box sx={currentRegionBarSx}>
            <Breadcrumbs
                region={region}
                regionParents={regionParents}
            />
            <Box sx={{ pt: {xs: 1, md: 0 }}}>
                <SelectSubRegion regionChildren={regionChildren} />
            </Box>
        </Box>
    </>
);

Header.propTypes = {
    region: PropTypes.object.isRequired,
    regionParents: PropTypes.array.isRequired,
    regionChildren: PropTypes.array.isRequired,
};

export default Header;