import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

import { useSearchParams } from "react-router-dom";

import { Helmet } from 'react-helmet-async';
import capitalizeWords from 'utils/capitalizeWords';

import { useTranslation } from 'react-i18next';

import servicesContext from 'servicesContext';

import RegionAmenities from './RegionAmenities';
import { getSelectionParamsFromURL, resetPagingParamInURL } from './categoriesSelectionParams';

const { regionAmenitiesService } = servicesContext;

const CATEGORIES_LANG = 'pl';
const PAGE_SIZE = 10;

const filterAmenityCategory = (category, categoryGroups, categoriesExclude, categoriesInclude) => {

    const { key, categoryGroupKey} = category;
    return (
        (!categoryGroups || categoryGroups.includes(categoryGroupKey))
        && (!categoriesExclude || !categoriesExclude.includes(key))
        && (!categoriesInclude || categoriesInclude.includes(key))
    );
};

const getCategoryGroupsAsString = (categoryGroups, categoryGroupsParam, t) => (
    categoryGroups
        .filter(({ key }) => !categoryGroupsParam || categoryGroupsParam.includes(key))
        .map(({ title }) => t(title))
        .join(', ')
);

const getCategoryNamesByKey = (categories) => {
    
    const categoryNames = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const { key, name } of categories) {
        categoryNames[key] = name;
    }

    return categoryNames;
};

const RegionAmenitiesSection = ({ region, categoryGroups }) => {

    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [selectionParams, setSelectionParams] = useState(null);

    const [categories, setCategories] = useState(null);
    const [categoryNames, setCategoryNames] = useState(null);

    const [selectedCategories, setSelectedCategories] = useState(null);
    const [searchText, setSearchText] = useState(null);

    const [amenities, setAmenities] = useState(null);
    const [amenitiesTotal, setAmenitiesTotal] = useState(null);
    const [categoryCounts, setCategoryCounts] = useState(null);

    const {
        categoryGroupsParam,
        categoriesExcludeParam,
        categoriesIncludeParam,
        currentPageParam,
    } = selectionParams || {};

    useEffect(() => {
        const selectionParams = getSelectionParamsFromURL(searchParams);
        setSelectionParams(selectionParams);
    }, [searchParams]);

    useEffect(() => {

        const fetch = async () => {
            const categories = await regionAmenitiesService.getAmenityCategories(
                categoryGroups.map(({ key }) => key),
                CATEGORIES_LANG,
            );
            
            setCategories(categories);
            setCategoryNames(
                getCategoryNamesByKey(categories),
            );
        }

        fetch();

    }, [region.id, categoryGroups]);

    useEffect(() => {

        if (categories) {
            setSelectedCategories(
                categories.filter(
                    (category) => filterAmenityCategory(
                        category,
                        categoryGroupsParam,
                        categoriesExcludeParam,
                        categoriesIncludeParam,
                    ),
                ),
            );    
        }

    }, [categories, categoryGroupsParam, categoriesExcludeParam, categoriesIncludeParam]);

    useEffect(() => {

        const fetch = async () => {
            if (selectedCategories && selectedCategories.length && currentPageParam) {

                const amenitiesResponse = await regionAmenitiesService.getAmenities(
                    region.id,
                    selectedCategories.map(({ key }) => key),
                    CATEGORIES_LANG,
                    (currentPageParam - 1) * PAGE_SIZE,
                    PAGE_SIZE,
                    searchText,
                );
                const { amenities, total, counts } = amenitiesResponse;
                setAmenities(amenities);
                setAmenitiesTotal(total);                 
                setCategoryCounts(counts);                 
            } else {
                setAmenities([]);
                setAmenitiesTotal(0);
                setCategoryCounts([]);
            }
        }

        fetch();

    }, [region.id, selectedCategories, currentPageParam, searchText]);


    const [searchTimeoutId, setSearchTimeoutId] = useState(null);

    const changeSearchText = (event) => {
        clearTimeout(searchTimeoutId);
        const timeoutId = setTimeout(() => {
            resetPagingParamInURL(setSearchParams);
            setSearchText(event.target.value)
        }, 300);
        setSearchTimeoutId(timeoutId);
    };

    return (
        <>
            { (!categoryGroupsParam || categoryGroupsParam.length > 0) &&
                <Helmet>
                    <title>{`Twoja Okolica .Online - ${capitalizeWords(region.name)} - ${getCategoryGroupsAsString(categoryGroups, categoryGroupsParam, t)}`}</title>
                </Helmet>
            }

            { categories !== null && selectedCategories !== null && amenities !== null && amenitiesTotal !== null && categoryCounts !== null &&
                <RegionAmenities
                    region={region}
                    categoryGroups={categoryGroups}
                    categories={categories}
                    categoryNames={categoryNames}
                    selectedCategories={selectedCategories}
                    currentPage={currentPageParam}
                    amenities={amenities}
                    pagesCount={Math.ceil(amenitiesTotal / PAGE_SIZE)}
                    categoryCounts={categoryCounts}
                    amenitiesTotal={amenitiesTotal}
                    onSearchTextChange={changeSearchText}
                />
            }
        </>
    );
};

RegionAmenitiesSection.propTypes = {
    region: PropTypes.object,
    categoryGroups: PropTypes.array,
};

export default RegionAmenitiesSection;
