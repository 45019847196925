import { useSelector, useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import { useTheme } from '@mui/system';
import { styled } from '@mui/material/styles';

import { removeLastRegion } from 'store/store';

import buildRegionLink from 'routes/buildRegionLink';

const StyledLink = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    textDecoration: 'none',
    textTransform: 'capitalize',
    color: theme.palette.grey[600],
    '&:hover': {
        textDecoration: 'underline',
    },
    fontSize: '0.875rem',
}));

const previousRegionBarSx = (theme) => ({
    borderBottom: `1px solid ${theme.palette.primary.lighter}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    px: 2,
    pt: 1,
    pb: 0.5,
});

const PreviousRegion = () => {

    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const visitedRegions = useSelector((state) => state.visitedRegions.visitedRegions);
    const previousRegion = visitedRegions.length > 1 && visitedRegions[visitedRegions.length - 2];

    const handleClick = () => {
        dispatch(removeLastRegion());
        const segments = previousRegion.refPath.split('$');
        const link = buildRegionLink(...segments);
        navigate(link);
    };

    return (
        <>
            { previousRegion &&
                <Box sx={previousRegionBarSx(theme)}>
                    <StyledLink onClick={handleClick}>
                        Poprzedni region: {previousRegion.name}
                    </StyledLink>
                </Box>
            }
        </>
    );
};

export default PreviousRegion;