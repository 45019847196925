const EVENTS = {
    REGION_WHATS_APP_CLICKED: 'region_whatsapp_clicked',
    REGION_TWITTER_CLICKED: 'region_twitter_clicked',
    REGION_FACEBOOK_CLICKED: 'region_facebook_clicked',
    REGION_LINKEDIN_CLICKED: 'region_linkedin_clicked',
    REGION_EMAIL_CLICKED: 'region_email_clicked',
    REGION_COPY_URL_CLICKED: 'region_copyurl_clicked',
    REGION_PRINT_CLICKED: 'region_print_clicked',   
    REGION_MAP_MAXIMIZED: 'region_map_maximized',
};

export default EVENTS;