import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/system';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

import buildRegionLink from 'routes/buildRegionLink';

import { formatTime, formatDistance } from './routeUtils';

const StyledLink = styled(Link)({
    color: 'inherit',
});

const RouteRow = ({ route, onRouteSelected }) => {

    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Box
            sx={{ '& :hover': { backgroundColor: 'grey.300', cursor: 'pointer' }}}
            onClick = {() => onRouteSelected(route) }
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'self-end',
                borderTop: `1px solid ${theme.palette.primary.lighter}`,
                p: 1,
            }}>
                <Box>
                    <Typography variant="body2" sx={{ color: 'grey.600' }}>
                        {t('regionDirections.labelRoute')}:
                        &nbsp;
                        <StyledLink
                            to={buildRegionLink(...route.startRegionRefPath.split('$'))}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {route.startRegionName}
                        </StyledLink>
                        &nbsp;-&nbsp;
                        <StyledLink
                            to={buildRegionLink(...route.destinationRegionRefPath.split('$'))}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {route.destinationRegionName}
                        </StyledLink>
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'grey.500' }}>
                        {t('regionDirections.labelDrivingTime')}: {formatTime(route.time)}
                        &nbsp;&nbsp;
                        {t('regionDirections.labelDrivingDistance')}: {formatDistance(route.distance)}
                    </Typography>
                </Box>
                <Box>
                    <ChevronRightIcon sx={{ color: 'grey.600' }} />
                </Box>
            </Box>
        </Box>
    );
};

RouteRow.propTypes = {
    route: PropTypes.object,
    onRouteSelected: PropTypes.func,
};

export default RouteRow;