import PropTypes from 'prop-types';

import RegionAmenitiesSection from './RegionAmenitiesSection';

const SERVICES_CATEGORY_GROUPS = [
    { key: 'shops', label: 'regionAmenities.amenity.shops', title: 'regionAmenities.amenity.shops.long' },
    { key: 'healthcare', label: 'regionAmenities.amenity.healthcare', title: 'regionAmenities.amenity.healthcare.long' },
    { key: 'financial', label: 'regionAmenities.amenity.financial', title: 'regionAmenities.amenity.financial.long' },
    { key: 'transportation', label: 'regionAmenities.amenity.transportation', title: 'regionAmenities.amenity.transportation.long' },
    { key: 'temples', label: 'regionAmenities.amenity.temples', title: 'regionAmenities.amenity.temples.long' },
    { key: 'basics', label: 'regionAmenities.amenity.basics', title: 'regionAmenities.amenity.basics.long' },
];

const RegionServicesSection = ({ region }) => (
    <RegionAmenitiesSection 
        region={region}
        categoryGroups={SERVICES_CATEGORY_GROUPS}
    />
);

RegionServicesSection.propTypes = {
    region: PropTypes.object,
};

export default RegionServicesSection;
